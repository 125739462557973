<template>
  <div class="app-box">
    <div class="top-box">
      <div class="header">
        <div class="search-input">
          <el-input :placeholder="$t('systemConfig.enterRoleName')" v-model="searchKey" @blur="getRole" @clear="getRole" clearable></el-input>
        </div>
        <div class="header-btns">
          <el-button v-if="roleCreate" class="concelbtn" size="medium" type="primary" @click="createRoles">{{
            $t('systemConfig.addRole')
          }}</el-button>
        </div>
      </div>
    </div>
    <div class="table-box">
      <add-role-drawer :drawerVisable.sync="drawerVisable" :mode="mode" :list="pageList" :roleInfo="roleInfo" @save="saveRole" />
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="序号">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="角色名称"> </el-table-column>
        <el-table-column prop="type" label="角色类型">
          <template slot-scope="scope">
            <div>{{ scope.row.type === 'normal' ? '普通类型' : '基础类型' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <div>{{ scope.row.status ? '启用' : '禁用' }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div v-if="roleUpdate || roleDelete">
              <el-button v-if="roleUpdate" size="mini" @click="editRole(scope.row)">编辑</el-button>
              <el-button v-if="scope.row.type === 'normal' && roleDelete" size="mini" @click="deleteRole(scope.row)">删除</el-button>
              <span v-if="!roleDelete && !roleUpdate">无操作权限</span>
            </div>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        v-if="count"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        background
        :total="count"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import AddRoleDrawer from './add-role';
import systemConfigApi from '@/api/modules/systemConfigApi.api.js';

export default {
  name: 'Assistant',
  components: {
    AddRoleDrawer,
  },
  computed: {
    roleCreate() {
      return this.$store.state.app.userConfig.permissionsKeys?.RoleManagementCreate;
    },
    roleUpdate() {
      return this.$store.state.app.userConfig.permissionsKeys?.RoleManagementUpdate;
    },
    roleDelete() {
      return this.$store.state.app.userConfig.permissionsKeys?.RoleManagementDelete;
    },
  },
  watch: {
    searchKey: function () {
      this.page = 1;
      this.pageSize = 10;
    },
  },
  data() {
    return {
      searchKey: '',
      page: 1,
      pageSize: 10,
      count: 0,
      drawerVisable: false,
      mode: 'create',
      pageList: [],
      roleInfo: {},
      tableData: [],
    };
  },
  created() {
    this.getRole();
    this.getMenus();
  },
  methods: {
    async getRole() {
      let params = { page: this.page, pagesize: this.pageSize };
      if (this.searchKey) {
        params.nameCont = this.searchKey;
      }
      const res = await systemConfigApi.roles('get', params);
      if (res && res.status === 200) {
        this.tableData = res?.data?.data?.roles || [];
        this.count = res?.data?.data?.count || 0;
      } else {
        this.$message.error(res.data.message);
      }
    },
    async getMenus() {
      const res = await systemConfigApi.getMenus();
      const list = res?.data?.data?.menus || [];
      list.forEach(item => {
        item.menuIds = item.menuIds || [];
        item.checkedAll = item.checkedAll || false;
        item.isIndeterminate = item.isIndeterminate || false;
      });
      this.pageList = list;
    },
    createRoles() {
      this.mode = 'create';
      this.roleInfo = {};
      this.pageList.forEach(item => {
        item.menuIds = [];
        item.checkedAll = false;
      });
      this.drawerVisable = true;
    },
    async editRole(row) {
      this.mode = 'edit';
      const res = await systemConfigApi.roles('get', { id: row.id });
      if (res && res.status === 200) {
        this.roleInfo = res?.data?.data || {};
        if (this.roleInfo.menuIds) {
          const idMap = {};
          this.roleInfo.menuIds.forEach(item => (idMap[item] = true));
          this.pageList.forEach(item => {
            item.menuIds = [];
            if (item.children) {
              item.children.forEach(menu => {
                if (idMap[menu.id]) {
                  item.menuIds.push(menu.id);
                }
              });
              item.checkedAll = item.children.length === item.menuIds.length;
            }
          });
        }
        this.drawerVisable = true;
      } else {
        this.$message.error(res.data.message);
      }
    },
    async deleteRole(row) {
      if (row.id) {
        const res = await systemConfigApi.roles('delete', { id: row.id });
        if (res && res.status === 200) {
          this.$message.success('删除成功！');
          this.getRole();
        } else {
          this.$message.error(res[1].message);
        }
      }
    },
    async saveRole(datas) {
      console.log(datas);
      const method = this.mode === 'create' ? 'post' : 'put';
      const res = await systemConfigApi.roles(method, datas);
      console.log('===', res);
      if (res && res.status === 200) {
        this.$message.success('保存成功！');
        this.drawerVisable = false;
        this.getRole();
      } else {
        this.$message.error(res.data.message);
      }
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getRole();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getRole();
    },
  },
};
</script>
<style lang="scss" scoped>
.app-box {
  padding: 18px 16px;
  flex-grow: 1;
  overflow-y: scroll;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 51px;
    .header-tit {
      height: 16px;
      line-height: 16px;
      padding-left: 8px;
      border-left: 3px solid#409EFF;
      font-size: 16px;
    }
    .search-input {
      min-width: 300px;
    }
    .header-btns {
      display: flex;
      align-items: center;
      height: 36px;
      font-size: 14px;
      .savebtn {
        margin-left: 16px;
      }
    }
  }

  .top-box,
  .table-box {
    background-color: #fff;
    padding: 10px 24px;
    border-radius: 2px;
    margin-bottom: 18px;
    .edit_from {
      margin-top: 30px;
      display: flex;
      justify-content: flex-start;
      max-width: 1300px;
      min-width: 900px;
      font-size: 14px;

      .tit {
        margin-bottom: 8px;
        i {
          margin-right: 3px;
          color: red;
        }
      }
      .el-form-item {
        margin-bottom: 0;
      }

      .vvww_colOne {
        width: 448px;
      }
      .colOne {
        display: flex;
        flex-direction: column;
        .projectname {
          .el-input {
            width: 100%;
            border-radius: 4px;
            height: 40px;
          }
        }

        .member {
          margin-top: 30px;
          .el-select {
            width: 100%;
            border-radius: 4px;
            height: 40px;
          }
          .multiple {
            margin: 4px 0 0 0;
            display: flex;
            flex-wrap: wrap;
            .el-tag {
              margin: 0px 4px 4px 0;
            }
          }
        }
      }

      .vvww_colTwo {
        width: 320px;
        margin-left: 48px;
      }
      .colTwo {
        display: flex;
        flex-direction: column;
        .director {
          .el-select {
            width: 100%;
            height: 40px;
            border-radius: 4px;
          }
        }

        .time {
          margin-top: 30px;
          ::v-deep .el-date-picker {
            width: 100%;
            border-radius: 4px;
            height: 40px;
          }
        }
      }

      .vvww_colThird {
        width: 320px;
        margin-left: 48px;
      }
      .colThird {
        display: flex;
        flex-direction: column;
        .chief {
          .el-select {
            width: 100%;
            height: 40px;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .bottom-box {
    margin-top: 16px;
    background-color: #fff;
    padding: 20px 24px 32px;
    border-radius: 2px;
  }

  ::v-deep .empty {
    height: 80%;

    .empty__image {
      width: 286px;
      height: 214px;
    }
    &.operation_Management_err {
      .empty__image {
        width: 267px;
        height: 225px;
      }
    }
  }
}
::v-deep.el-table {
  &::before,
  .el-table__fixed::before {
    height: 0;
  }

  //table_header
  .el-table__header-wrapper {
    border: 1px solid #e8eaec;
    .el-table__header th.el-table__cell {
      background-color: #f8f8f8f9;
      color: #515a6e;
      font-weight: 600;
      padding: 6px 0;
      height: 48px;
      box-sizing: border-box;
      border-bottom: 1px solid #e8eaec;
    }
  }

  /*  .el-table__empty-block {
    min-height: 350px;
  } */

  .el-table__body-wrapper {
    transform: translateY(-1px);
  }

  //table_body
  .el-table__row {
    border: 1px solid #dcdee2;
    border-color: #dcdee2;
  }
  .el-table__body td.el-table__cell {
    // cursor: pointer;
    padding: 6px 0;
    height: 40px;
    // border-right: 1px solid #dcdee2;
    border-color: #dcdee2;
    &:first-of-type {
      border-left: 1px solid #dcdee2;
    }
    &:last-of-type {
      border-right: 1px solid #dcdee2;
    }

    .hours-input {
      .el-input__inner {
        color: #515a6e;
        border: none;
        text-align: left;
        height: 29px;
        line-height: 29px;
        background-color: #fff;
      }
    }

    .el-button {
      // width: 126px;
      // height: 20px;
      color: #2d8cf0;
    }
  }
}
.pagination {
  text-align: right;
  margin: 10px;
}
</style>
