<template>
  <el-drawer
    id="AddRoleDrawer"
    :destroy-on-close="true"
    :visible.sync="drawerVisable"
    :before-close="handleClose"
    :size="drawerSize"
    :title="mode === 'create' ? $t('systemConfig.createRole') : $t('systemConfig.editRole')"
  >
    <div class="app-box">
      <div class="top-box">
        <el-form :model="dataForm" ref="dataForm" :rules="rules" class="filter-form">
          <div class="colStyle">
            <el-form-item prop="name" :label="$t('systemConfig.roleName')">
              <el-input
                v-model="dataForm.name"
                clearable
                :placeholder="this.$t('systemConfig.enterRoleName')"
                size="mini"
                style="width: 230px"
              ></el-input>
            </el-form-item>
            <el-form-item prop="roleType" :label="$t('systemConfig.roleType')">
              <el-input v-model="dataForm.roleType" size="mini" style="width: 230px" disabled></el-input>
            </el-form-item>
            <el-form-item class="select-width" prop="status" :label="$t('systemConfig.roleStatus')">
              <el-switch v-model="dataForm.status"></el-switch>
            </el-form-item>
          </div>
        </el-form>
      </div>

      <div class="header">
        {{ $t('systemConfig.menuPermissions') }}
      </div>
      <div class="bottom-box">
        <el-table :data="list" style="width: 100%; max-height: 595px; overflow-y: auto">
          <el-table-column prop="name" label="页面名称" width="360"> </el-table-column>
          <el-table-column label="权限">
            <template slot-scope="scope">
              <el-checkbox :indeterminate="scope.row.isIndeterminate" v-model="scope.row.checkedAll" @change="handleCheckAllChange(scope.row)"
                >全选</el-checkbox
              >
              <el-checkbox-group v-model="scope.row.menuIds" @change="handlePermissionsChange(scope.row)">
                <el-checkbox v-for="item in scope.row.children" :key="item.id" :label="item.id">{{ item.name }}</el-checkbox>
              </el-checkbox-group>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom-btns">
        <el-button @click="handleClose" class="createNewbtn" size="medium">{{ $t('systemConfig.cancel') }}</el-button>
        <el-button @click="saveRole" class="savebtn" size="medium" type="primary">{{ $t('systemConfig.ok') }}</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: 'AddRoleDrawer',
  props: {
    drawerVisable: {
      typeof: Boolean,
      default: false,
    },
    mode: {
      typeof: String,
      default: 'create',
    },
    list: {
      typeof: Array,
      default: [],
    },
    roleInfo: {
      typeof: Object,
      default: {},
    },
  },
  watch: {
    roleInfo: function () {
      const obj = {
        name: this.roleInfo.name || '',
        roleType: '普通角色',
        status: this.roleInfo.id ? this.roleInfo.status : true,
      };
      this.dataForm = obj;
    },
  },
  data() {
    return {
      drawerSize: 980,
      dataForm: {
        name: '',
        roleType: '普通角色',
        status: true,
      },
      // list: [],
      rules: {
        name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
      },
    };
  },
  methods: {
    handleClose() {
      const fn = validate => {
        if (!validate) {
          this.$confirm('修改的数据未保存，是否关闭？')
            .then(() => {
              this.$emit('update:drawerVisable', false);
            })
            .catch(() => {});
        } else {
          this.$emit('update:drawerVisable', false);
        }
      };
      if (this.mode === 'create') {
        if (this.dataForm.name || this.dataForm.status === false) {
          return fn(false);
        }
        if (this.list.find(item => item.menuIds.length > 0)) {
          return fn(false);
        }
      }
      if (this.mode === 'edit') {
        if (this.dataForm.name !== this.roleInfo.name || this.dataForm.status !== this.roleInfo.status) {
          return fn(false);
        }
        const origin = this.roleInfo.menuIds.sort((a, b) => a - b).join();
        const newIds = this.list
          .reduce((pre, cur) => {
            const parent = cur.checkedAll ? [cur.id] : [];
            return pre.concat(cur.menuIds).concat(parent);
          }, [])
          .sort((a, b) => a - b)
          .join();
        if (origin !== newIds) {
          return fn(false);
        }
      }
      fn(true);
    },
    handleCheckAllChange(row) {
      row.menuIds = row.checkedAll ? row.children.map(item => item.id) : [];
      row.isIndeterminate = false;
    },
    handlePermissionsChange(row) {
      row.checkedAll = row.menuIds.length === row.children.length;
      row.isIndeterminate = !!(!row.checkedAll && row.menuIds.length);
      console.log(row.menuIds);
    },
    saveRole() {
      this.$refs.dataForm.validate(async valid => {
        if (!valid) {
          return;
        }
        const menuIds = this.list.reduce((pre, cur) => {
          const parent = cur.checkedAll ? [cur.id] : [];
          return pre.concat(cur.menuIds).concat(parent);
        }, []);
        const data = { name: this.dataForm.name, status: this.dataForm.status, menuIds };
        if (this.mode === 'edit' && this.roleInfo.id) {
          data.id = this.roleInfo.id;
        }
        this.$emit('save', data);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#AddRoleDrawer {
  ::v-deep .el-drawer__header {
    font-size: 16px;

    font-weight: 700;
    color: #17233d;
    line-height: 24px;
    height: 63px;
    margin: 0;
    border-bottom: 1px solid #e8eaec;
  }
  .app-box {
    padding: 0;
    flex-grow: 1;
    overflow-y: scroll;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 14px;
      border-bottom: 1px solid #e8eaec;
      // height: 32px;
      padding: 20px 32px;
      font-weight: 700;
    }

    .top-box {
      width: 100%;
      font-size: 14px;
      background-color: #fff;
      border-radius: 2px;
      font-size: 16px;

      font-weight: 500;
      color: #17233d;
      // border-top: 1px solid #e9e9e9;
      .filter-form {
        display: flex;
        justify-content: flex-start;
        .left-form {
          display: flex;
          justify-content: flex-start;
          max-width: 1300px;
          min-width: 900px;
          font-size: 14px;
          position: relative;
          .el-form-item {
            margin-bottom: 0;
            ::v-deep .el-form-item__label {
              display: block;
              text-align: left;
              float: none;
            }
          }
          .form-border {
            position: absolute;
            right: 0;
            height: 100%;
            top: 12%;
          }
          .form-border:after {
            content: '';
            position: absolute;
            right: 0;
            height: 250px;
            width: 1px;
            background: #fff;
            opacity: 0.5;
          }
        }
        .right-form {
          margin-top: 30px;
          .el-form-item {
            // margin-bottom: 30px;
            ::v-deep .el-form-item__label {
              display: inline-block;
              text-align: left;
              float: none;
            }
            ::v-deep .el-form-item__content {
              display: inline-block;
            }
          }
          padding-left: 64px;
          display: flex;
          flex-direction: column;
        }
      }

      .tit {
        margin-bottom: 8px;
        i {
          margin-right: 3px;
          color: red;
        }
      }

      .colStyle {
        // width: 224px;
        padding: 10px 32px;
        margin-right: 56px;
        display: -webkit-box;
        // display: flex;
        // flex-direction: column;
        // flex-wrap: nowrap;
        .el-form-item {
          margin-right: 30px;
          margin-bottom: 0;
          margin-top: 10px;
        }
        .projectname {
          .el-input {
            width: 100%;
            border-radius: 4px;
            height: 40px;
          }
        }
        .member {
          margin-top: 30px;
          .el-select {
            width: 100%;
            border-radius: 4px;
            height: 40px;
          }
          .multiple {
            margin: 4px 0 0 0;
            display: flex;
            flex-wrap: wrap;
            .el-tag {
              margin: 0px 4px 4px 0;
            }
          }
        }
      }
    }

    .bottom-box {
      background-color: #fff;
      padding: 0 24px 32px;
      border-radius: 2px;
      margin-top: 30px;
      height: 600px;
      ::v-deep.el-table {
        &::before,
        .el-table__fixed::before {
          height: 0;
        }

        //table_header
        .el-table__header-wrapper {
          border: 1px solid #e8eaec;
          .el-table__header th.el-table__cell {
            background-color: #f8f8f8f9;
            color: #515a6e;
            font-weight: 600;
            padding: 6px 0;
            height: 48px;
            box-sizing: border-box;
            border-bottom: 1px solid #e8eaec;
          }
        }

        /*      .el-table__empty-block {
          min-height: 350px;
        } */

        .el-table__body-wrapper {
          transform: translateY(-1px);
        }

        //table_body
        .el-table__row {
          border: 1px solid #dcdee2;
          border-color: #dcdee2;
        }
        .el-table__body td.el-table__cell {
          // cursor: pointer;
          padding: 6px 0;
          height: 40px;
          // border-right: 1px solid #dcdee2;
          border-color: #dcdee2;
          .el-checkbox-group {
            margin-left: 24px;
          }
          .cell {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
          }
          &:first-of-type {
            border-left: 1px solid #dcdee2;
          }
          &:last-of-type {
            border-right: 1px solid #dcdee2;
          }

          .hours-input {
            .el-input__inner {
              color: #515a6e;
              border: none;
              text-align: left;
              height: 29px;
              line-height: 29px;
              background-color: #fff;
            }
          }

          .el-button {
            // width: 126px;
            // height: 20px;
            color: #2d8cf0;
          }
        }
      }
    }

    .bottom-btns {
      text-align: right;
      margin: 10px 20px;
    }
    ::v-deep .empty {
      height: 80%;

      .empty__image {
        width: 286px;
        height: 214px;
      }
      &.operation_Management_err {
        .empty__image {
          width: 267px;
          height: 225px;
        }
      }
    }
  }
}
</style>
